import * as React from "react"

export function Nav() {
  return (
    // <ul className="nav">
    //   <li className="nav-item">
    //     <Link to="/" className="nav-link active text-light" aria-current="page">
    //       Home
    //     </Link>
    //   </li>
    // </ul>
    <></>
  )
}
